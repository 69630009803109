import React from "react";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaGithub,
  FaTwitter,
} from "react-icons/fa";
const Banner = () => {
  const [state] = React.useState({
    title: "Ahoj! Volám sa Mário 👋",
    text:
      "Som začinajúci WEB developer",
    image: "/images/ja.png",
  });
  return (
    <header className="header">
      <div className="container">
        <div className="row">
          <div className="col-6">
            <div className="header__content">
              <div className="header__section">
                <ul className="header__ul">
                  <a href="https://www.facebook.com/profile.php?id=100009941675656" target="_blank">
                  <li>
                    <FaFacebookF className="headerIcon" />
                  </li>
                  </a>
                  <a href="https://www.linkedin.com/in/mariopanenko/" target="_blank">
                  <li>
                    <FaLinkedinIn className="headerIcon" />
                  </li>
                  </a>
                  <a href="https://github.com/majosnv" target="_blank">
                  <li>
                    <FaGithub className="headerIcon" />
                  </li>
                  </a>
                  <a href="https://twitter.com/majosnv" target="_blank">
                  <li>
                    <FaTwitter className="headerIcon" />
                  </li>
                  </a>
                </ul>
                <h1>{state.title}</h1>
                <p>{state.text}</p>
                <div className="header__buttons">
                  <a href="#projekty" className="btn btn-outline">
                    Moje projekty
                  </a>
                  {/*&nbsp;&nbsp;&nbsp;
                  <a href="" className="btn btn-smart">
                    <FaPlay className="play" />
                  </a>*/}
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="banner__img">
              <img src={state.image} alt="Ja" />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Banner;
