import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaGithub,
  FaLinkedinIn,
} from "react-icons/fa";
const Contact = () => {
  return (
    <div className="contact" id="kontakt">
      <div className="container">
        <div className="contactSection">
          <div className="row justifyConter">
            <div className="col-6">
              <div className="contactSection-logo">
                <a href="#"><strong>panenko</strong><span>.sk</span></a>
              </div>
              <p>
              Máte na mňa všeobecnú otázku?
              </p>
              <ul className="contactCircles">
                <a href="https://www.facebook.com/profile.php?id=100009941675656" target="_blank">
                <li>
                    <FaFacebookF className="headerIcon" />
                </li>
                </a>
                <a href="https://www.linkedin.com/in/mariopanenko/" target="_blank">
                <li>
                    <FaLinkedinIn className="headerIcon" />
                </li>
                </a>
                <a href="https://github.com/majosnv" target="_blank">
                <li>
                    <FaGithub className="headerIcon" />
                </li>
                </a>
                <a href="https://twitter.com/majosnv" target="_blank">
                <li>
                    <FaTwitter className="headerIcon" />
                </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
