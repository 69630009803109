import React from "react";
import "./App.css";
import Uvod from "./components/Uvod";
import Nav from "./components/Nav";
import Skills from "./components/Skills";
import Omne from "./components/Omne";
import Projekty from "./components/Projekty";
import Kontakt from "./components/Kontakt";

function App() {
  return (
    <div>
      <Uvod />
      <Nav />
      <Omne />
      <Projekty />
      <Skills />
      <Kontakt />
    </div>
  );
}

export default App;
