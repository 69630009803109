import React from "react";
import { FaAlignJustify } from "react-icons/fa";
const Nav = () => {
  const [state, setState] = React.useState(true);
  return (
    <nav className="navbar">
      <div className="container">
        <div className="navbar__container">
          <ul className="navbar__left">
            <div className="navbar__left-logo">
              {/* Tak sa dáva komentár */}
              <a href="#"><strong>panenko</strong><span>.sk</span></a>
            </div>
          </ul>
          {state ? (
            <ul className="navbar__right">
              <li>
                <a href="#">Domov</a>
              </li>
              <li>
                <a href="#omne">O mne</a>
              </li>
              <li>
                <a href="#projekty">Projekty</a>
              </li>
              <li>
                <a href="#skills">Skills</a>
              </li>
              <li>
                <a href="#kontakt">Kontakt</a>
              </li>
            </ul>
          ) : (
            ""
          )}
        </div>
      </div>
      {/*<div className="toggle" onClick={() => setState(!state)}>*/}
      <div className="toggle">
        {/*<FaAlignJustify />*/}
        <a href="#"><img src="/images/ja.png" alt="Ja" width="80"/></a>
      </div>
    </nav>
  );
};

export default Nav;
