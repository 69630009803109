import React from "react";

const About = () => {
  const [header] = React.useState({
    subHeader: "O mne",
    text:
      "Kto som zač?",
  });
  const [state] = React.useState([
    { id: 1, title: "Name:", text: "Jonathan Doe" },
    { id: 2, title: "Email:", text: "example@domain.com" },
    { id: 3, title: "Phone:", text: "+1 023 454 345" },
    { id: 4, title: "Linkedin", text: "Jonathan_123" },
  ])
  return (
    <div className="about" id="omne">
      <div className="container">
        <div className="common">
          <h1 className="mainHeader">{header.subHeader}</h1>
          <p className="mainContent">{header.text}</p>
          <div className="commonBorder"></div>
        </div>
        <div className="row  h-650 alignCenter">
          <div className="col-6">
            <div className="about__img">
              <img src="/images/ja.png" alt="Ja"/>
            </div>
          </div>
          <div className="col-6">
            <div className="about__info">
              <h1>👇</h1>
              <div className="about__info-p1">
              Som študent vysokej školy TUKE a zároveň snažím sa zlepšovať v oblasti WEB developera. Programujem pasívnejšie od strednej školy, teraz už aktívnejšie. Dal by som prednosť IT praxi a skúsenostiam vo firme
              </div>
              <div className="about__info-p2">
              Vo voľnom čase bicyklujem, jazdím na motorke alebo večer na aute, zaujímam sa o crypto a financiach. Žijem v Spišskej Novej Vsi
              </div>
              <h1 className="emojko">☝️</h1>
              {/*<div className="info__contacts">
                <div className="row">
                  {state.map((info) => (
                    <div className="col-6">
                      <strong>{info.title}</strong>
                      <p>{info.text}</p>
                    </div>
                  ))}
                </div>
                  </div>*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
