import React from "react";
import {
  FaJs,
  FaCss3,
  FaGitAlt,
  FaHtml5,
  FaReact,
  FaBootstrap,
} from "react-icons/fa";
const Skills = () => {
  const [header] = React.useState({
    mainHeader: "",
    subHeading: "Moje skilly",
    text:
      "V čom pracujem a zlepšujem sa",
  });
  const [state] = React.useState([
    {
      id: 1,
      icon: <FaHtml5 className="commonIcons" />,
      heading: "HTML",
      text:
      <div class="bar">
        <div class="skills_bar html">70%</div>
      </div>,
    },
    {
      id: 2,
      icon: <FaCss3 className="commonIcons" />,
      heading: "CSS3",
      text:
      <div class="bar">
        <div class="skills_bar css">50%</div>
      </div>,
    },
    {
      id: 3,
      icon: <FaJs className="commonIcons" />,
      heading: "JS",
      text:
      <div class="bar">
        <div class="skills_bar js">15%</div>
      </div>,
    },
    {
      id: 4,
      icon: <FaGitAlt className="commonIcons" />,
      heading: "Git",
      text:
      <div class="bar">
        <div class="skills_bar git">40%</div>
      </div>,
    },
    {
      id: 5,
      icon: <FaReact className="commonIcons" />,
      heading: "React",
      text:
      <div class="bar">
        <div class="skills_bar react">15%</div>
      </div>,
    },
    {
      id: 6,
      icon: <FaBootstrap className="commonIcons" />,
      heading: "Bootstrap",
      text:
      <div class="bar">
        <div class="skills_bar bootstrap">40%</div>
      </div>,
    },
  ]);
  return (
    <div className="skills" id="skills">
      <div className="container">
        <div className="skills__header">
          <div className="common">
            <h3 className="heading">{header.mainHeader}</h3>
            <h1 className="mainHeader">{header.subHeading}</h1>
            <p className="mainContent">{header.text}</p>
            <div className="commonBorder"></div>
          </div>

          <div className="row bgMain">
            {state.map((info) => (
              <div className="col-4 bgMain">
                <div className="skills__box">
                  {info.icon}
                  <div className="skills__box-header">{info.heading}</div>
                  {info.text}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
