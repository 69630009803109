import React from "react";
import {
  FaGithub,
} from "react-icons/fa";

const Projects = () => {
  const [header] = React.useState({
    subHeading: "Projekty",
    text:
      "Vlastné projekty ktoré som vytvoril",
  });
  const [state] = React.useState([
    {
      id: 1,
      heading: "Jednoduché portofolio",
      img: <img src="/images/projekt_1.png" alt="Prvý projekt"/>,
      msg1: "Video pozadie + názov domény a linky na social media",
      msg2: <a href="https://github.com/majosnv" className="btn btn-outline">Github <FaGithub className="headerIcon"/></a>,
      msg3: "HTML",
      msg4: "CSS",
      msg5: "JS",
    },
    {
      id: 2,
      heading: "SMMA stránka",
      img: <img src="/images/projekt_2.png" alt="Druhý projekt"/>,
      msg1: "Web pre Social Media Marketing Agency",
      msg2: <a href="https://github.com/majosnv" className="btn btn-outline">Github <FaGithub className="headerIcon"/></a>,
      msg3: "HTML/CSS",
      msg4: "Bootstrap",
      msg5: "JS",
    },
    {
      id: 3,
      heading: "Maturitné témy",
      img: <img src="/images/projekt_3.png" alt="Tretí projekt"/>,
      msg1: "PHP login systém po prihlasení témy na maturitu",
      msg2: <a href="https://github.com/majosnv/maturitnetemy-web" target="_blank" className="btn btn-outline">Github <FaGithub className="headerIcon"/></a>,
      msg3: "PHP",
      msg4: "MySQL",
      msg5: "HTML/CSS",
    },
  ]);
  return (
    <div className="projects" id="projekty">
      <div className="container">
        <div className="common">
          <h3 className="heading">{header.mainHeader}</h3>
          <h1 className="mainHeader">{header.subHeading}</h1>
          <p className="mainContent">{header.text}</p>
          <div className="commonBorder"></div>
        </div>
        <div className="row">
          {state.map((projects) => (
            <div className="col-4" key={projects.id}>
              <div className="project">
                <div className="projectHeading">{projects.heading}</div>
                <div className="project__rs">
                  {projects.img}
                  <div class="hovercap">
                    {projects.msg1}
                  </div>
                </div>
                <ul>
                  <li className="btn btn-outline">{projects.msg3}</li>
                  <li className="btn btn-outline">{projects.msg4}</li>
                  <li className="btn btn-outline">{projects.msg5}</li>
                </ul>
                <div className="project__btn">
                  {projects.msg2}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Projects;